<template>
  <div class="noboobs">
    <h1>{{ msg }}</h1>
  </div>
</template>

<script>
export default {
  name: 'NoBoobs',
  props: {
    msg: String
  }
}
</script>

<style scoped>
h1 {
  margin: 20px 0 0;
}
</style>
