<template>
  <img alt="logo" src="./assets/logo.png">
  <NoBoobs msg="No boobs here please..."/>
</template>

<script>
import NoBoobs from './components/NoBoobs.vue'

export default {
  name: 'App',
  components: {
    NoBoobs
}
}
</script>

<style>
#app {
  text-align: center;
  margin-top: 60px;
}
</style>
